<template lang="pug">
vue-index(
  :center="center"
  :zoom="zoom"
  :tracingMarkersCliente="tracingMarkersCliente"
  :tracingMarkersCedi="tracingMarkersCedi"
  :tracing="tracingPoint"
  :tracingInfo="tracingInfo"
  :visibleTracing="visibleTracing"
  :tracingMarkersConductor="tracingMarkersConductor"
  :visibleTracingPoint="visibleTracingPoint"
  :polylineArray="polylineArray"
  :urlMapa="urlMapa"
)
</template>

<script>

import MapIndex from "@/components/MapSP/Index"
// import axios from "axios"

const NAME = "MapIndex";

export default {
  name: NAME,
  components: {
    "vue-index": MapIndex,
  },
  data() {
    return {
      dataUser: null,
      code: ''
    }
  },
  computed: {
    geojson: {
      get () {
        return this.$store.state.map.geojson
      }
    },
    urlMapa:{
      get () {
        return this.$store.state.urlMapa
      }
    },
    geojsonZone: {
      get () {
        return this.$store.state.map.geojsonZone
      }
    },
    geojsonPoint: {
      get () {
        return Object.assign({},this.$store.state.map.geojsonPoint)
      }
    },
    geojsonRoute: {
      get () {
        return this.$store.state.map.geojsonRoute
      }
    },
    center: {
      get () {
        return this.$store.state.map.center
      }
    },
    zoom: {
      get () {
        return this.$store.state.map.zoom
      }
    },
    polylineArray: {
      get () {
        return this.$store.state.map.polylineArray
      }
    },
    arrayPoint: {
      get () {
        return this.$store.state.map.arrayPoint
      }
    },
    markersService: {
      get () {
        return Object.assign({}, this.$store.state.map.markersService)
      }
    },
    tracingMarkersCliente: {
      get () {
        return Object.assign({}, this.$store.state.map.tracingMarkersCliente)
      }
    },
    tracingMarkersCedi: {
      get () {
        return Object.assign({}, this.$store.state.map.tracingMarkersCedi)
      }
    },
    tracingMarkersConductor: {
      get () {
        return Object.assign({}, this.$store.state.map.tracingMarkersConductor)
      }
    },
    tracingStatusArray: {
      get () {
        return this.$store.state.map.tracingStatusArray
      }
    },
    visibleTracingPoint: {
      get () {
        return this.$store.state.map.visibleTracingPoint
      }
    },
    tracingPoint: {
      get () {
        return this.$store.state.map.tracingPoint
      }
    },
    tracingInfo: {
      get () {
        return this.$store.state.map.tracingInfo
      }
    },
    visibleTracing: {
      get () {
        return this.$store.state.map.visibleTracing
      }
    }
  },
  // methods: {
  //   setSave(value) {
  //     this.searchServices(value)
  //   },
  //   searchServices(code) {
  //     this.$validator.validateAll().then(result => {
  //       if (result) {
  //         let url = `services/search/`
  //         let data = {
  //           code: code
  //         }
  //         HTTP.setHeader(true)
  //         HTTP.query(url, data)
  //           .then((response) => {
  //             this.infoData = response.data.data
  //             if (this.infoData.length > 0) {
  //               console.log(this.infoData)
  //               // this.$emit("infoData", this.infoData, code)
  //             } else {
  //               this.$vs.notify({
  //                 text: this.$t("mapview.no_search"),
  //                 color: "#64cc52",
  //                 iconPack: "feather",
  //                 icon: "icon-check-circle"
  //               })
  //             }
  //               this.show_button = false
  //           })
  //           .catch(response => {
  //             ErrorNotify.formsErrors({
  //               data: response.response,
  //               ob: this
  //             })
  //             this.show_button = false
  //           })
  //       } else {
  //         this.show_button = false
  //       }
  //     })
  //   },
  // },
  // mounted() {
  //   if (this.$route.query.hasOwnProperty('guia')) {
  //     this.code = this.$route.query.guia
  //     // this.$router.push({ name: 'searchSp', params: { code: this.code } })
  //     this.setSave(this.code)
  //   }
  //   // axios
  //   //   .post("/v1/users/check", this.$route.query)
  //   //   .then((response) => {
  //   //     this.dataUser = response.data.data
  //   //     if (this.dataUser != null) {
  //   //       if (!this.dataUser[0].config.config[0].value) {
  //   //         this.$swal({
  //   //           position: 'center',
  //   //           type: 'warning',
  //   //           showConfirmButton: false,
  //   //           allowOutsideClick: false,
  //   //           text: this.$t('permission_denied_map')
  //   //         })
  //   //       }
  //   //     } else {
  //   //       this.$swal({
  //   //         position: 'center',
  //   //         type: 'warning',
  //   //         showConfirmButton: false,
  //   //         allowOutsideClick: false,
  //   //         text: this.$t('permission_denied_map')
  //   //       })
  //   //     }
  //   //   })
  //   //   .catch((response) => {})//eslint-disable-line
  // }
}
</script>