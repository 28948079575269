<template lang="pug">
l-map.map-response(
  ref="map"
  :zoom="zoom"
  :center="center"
  :maxBounds="[[-89.98155760646617, -180],[89.99346179538875, 180]]"
  :options="{ zoomControl: false }"
)
  l-tile-layer(
    v-for="tileProvider in tileProviders"
    :key="tileProvider.name"
    :name="tileProvider.name"
    :visible="tileProvider.visible"
    :url="urlMapa"
    :attribution="tileProvider.attribution"
    layer-type="base"
  )
  l-polyline(
    v-if="polylineArrayPoint.length > 0"
    :latLngs="polylineArrayPoint"
    color="green"
  )
  l-marker(
    v-if="showingIconCar"
    :visible="visibleTracing"
    :lat-lng="tracing"
    :icon="icon_car"
  )
    l-popup(
      :content="markerTooltipTracingInfo(tracingInfo)"
    )
    l-tooltip(
      :content="markerTooltipTracingInfo(tracingInfo)"
    )
  l-marker(
    v-if="visibleTracingPoint"
    v-for="marker in tracingMarkersCliente"
    :key="marker.id"
    :visible="marker.visible"
    :draggable="marker.draggable"
    :lat-lng="marker.position"
    :icon="icon_home"
    @click="markerPointArrayTracingCliente(marker)"
  )
    l-popup(
      :content="markerTooltipTracingCliente(marker.tooltip)"
    )
    l-tooltip(
      :content="markerTooltipTracingCliente(marker.tooltip)"
    )
  l-marker(
    v-if="visibleTracingPoint"
    v-for="marker in tracingMarkersCedi"
    :key="marker.id"
    :visible="marker.visible"
    :draggable="marker.draggable"
    :lat-lng="marker.position"
    :icon="icon_cedi"
    @click="markerPointArrayTracingCedi(marker)"
  )
    l-popup(
      :content="markerTooltipTracingCedi(marker.tooltip)"
    )
    l-tooltip(
      :content="markerTooltipTracingCedi(marker.tooltip)"
    )
  l-marker(
    v-for="markerConductor in tracingMarkersConductor"
    :key="markerConductor.id"
    :visible="markerConductor.visible"
    :draggable="markerConductor.draggable"
    :lat-lng="markerConductor.position"
    @click="markerPointArrayTracingConductor(markerConductor)"
  )
    l-popup(
      :content="markerTooltipTracingConductor(markerConductor.tooltip)"
    )
    l-tooltip(
      :content="markerTooltipTracingConductor(markerConductor.tooltip)"
    )
</template>
<script>

export default {
  props: {
    polylineArray: {
      type: Array,
      required: false,
      default: () => []
    },
    center: {
      type: Array,
      required: false
    },
    zoom: {
      type: Number,
      required: false,
      default: 5
    },
    tracing: {
      type: Object,
      required: false
    },
    tracingInfo: {
      required: false
    },
    tracingMarkersCliente: {
      type: Object,
      required: false
    },
    tracingMarkersCedi: {
      type: Object,
      required: false
    },
    tracingMarkersConductor: {
      type: Object,
      required: false
    },
    visibleTracing: {
      type: Boolean,
      required: false
    },
    visibleTracingPoint: {
      type: Boolean,
      required: false
    },
    urlMapa: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      draw: {
        control: {},
        editableLayers: {},
      },
      markers: [
        {
          position: L.latLng(47.413220, -1.219482),// eslint-disable-line
          text: "Marker 1"
        },
        {
          position: L.latLng(47.313220, -1.219482),// eslint-disable-line
          text: "Marker 2"
        },
     ],
      tileOptions: {
        maxZoom: 50,
        minZoom: 3.5,
        minNativeZoom: 1,
        zoomControl: false,
        detectRetina: true,
        updateWhenIdle: true,
        keepBuffer: 10,
      },
      icon: L.divIcon({// eslint-disable-line
        className: "marker-icon-wrapper marker-icon-wrapper-3333",
        html: `<img style="width: 21px;" src="${require("@/assets/images/icon/pointmap.png")}" >`,
      }),
      icon_car: L.divIcon({// eslint-disable-line
        className: "marker-icon-wrapper",
        html: `<img style="width: 31px;" src="${require("@/assets/images/icon/iconomapa.png")}" >`,
      }),
      icon_cedi: L.icon({// eslint-disable-line
        iconUrl: require("@/assets/images/icon/fabrica.png"),
        iconSize: [32, 37]
      }),
      icon_home: L.icon({// eslint-disable-line
        iconUrl: require("@/assets/images/icon/mansion.png"),
        iconSize: [32, 37]
      }),
      icon_tracing: L.divIcon({// eslint-disable-line
        className: "marker-icon-wrapper",
        html: `<div class="blob purple"></div>`,
      }),
      tileProviders: [
        {
          name: "Color",
          visible: true,
          attribution: '&copy; <a target="_blank" href="#">SmartQuick</a>',
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "Gris",
          visible: false,
          url: "http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png",
          attribution: '&copy; <a target="_blank" href="#">SmartQuick</a>',
        },
      ],
      position: {
        lng: 0,
        lat: 0,
      },
      markerPoint: null,
      pointMark: null,
      showGeojson: false,
      map: {},
      fillColor: "#000",
      url: "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png",
      attribution: '&copy <a href="http://osm.org/copyright">SmartQuick</a> contributors',
    }
  },
  mounted() {
    this.setMap()
  },
  computed: {
    polylineArrayPoint: {
      get() {
        return this.polylineArray
      },
      set(val) {
        return val
      }
    },
    showingIconCar() {
      return !!this.tracing.lat && !!this.tracing.lng
    },
  },
  methods: {
    setMap() {
      this.map = this.$refs.map.mapObject
    },
    openPopup (event) {
      this.$nextTick(() => {
        event.target.openPopup()
      })
    },
    markerPointArrayTracingCliente (val) {
      this.$emit('markerPointArrayTracingCliente', val)
    },
    markerTooltipTracingInfo(val) {
      return `<div>
        <table style="width:100%">
          <tr>
            <th>${this.$t("map_full.coordinates")}: </th>
            <td>
              X: ${val.lat}
              <br>
              Y: ${val.lng}
            </td>
          </tr>
          <tr>
            <th>placa del vehículo: </th>
            <td>
              ${val.placa}
            </td>
          </tr>
        </table>
      </div>`
    },
    markerTooltipTracingCliente (val) {
      return `<div>
        <table style="width:100%">
          <tr>
            <th>Nombre Cliente:</th>
            <td>${val.nombre_cliente} </td>
          </tr>
          <tr>
            <th>Dirección:</th>
            <td>${val.direccion}</td>
          </tr>
        </table>
      </div>`
    },
    markerPointArrayTracingCedi (val) {
      this.$emit('markerPointArrayTracingCedi', val)
    },
    markerTooltipTracingCedi (val) {
      return `<div>
        <table style="width:100%">
          <tr>
            <th>Nombre Cedi:</th>
            <td>${val.cedi} </td>
          </tr>
        </table>
      </div>`
    },
    markerPointArrayTracingConductor (val) {
      this.$emit('markerPointArrayTracingConductor', val)
    },
    markerTooltipTracingConductor (val) {
      return `<div>
        <table style="width:100%">
          <tr>
            <th>Nombre del conductor:</th>
            <td>${val.name} </td>
          </tr>
          <tr>
            <th>placa del vehículo:</th>
            <td>${val.placa}</td>
          </tr>
          <tr>
            <th>Celular:</th>
            <td>${val.celular}</td>
          </tr>
        </table>
      </div>`
    },
  }
}
</script>
<style>
.map-response {
  width: 100%;
  height: 100%;
  min-height: 350px;
  min-width: 300px;
  display: block;
}
.leaflet-popup-content-wrapper {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
}
.marker-icon-wrapper-3333 {
  margin-left: -10px !important;
  margin-top: -38px !important;
}
/* .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20'><circle cx='5' cy='5' r='5' /></svg>") no-repeat;
  background-color: #FFF;
} */
.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
  border: 2px solid white;
  animation: pulse-black 2s infinite;
}

.blob.purple {
  background: rgba(142, 68, 173, 1);
  box-shadow: 0 0 0 0 rgba(142, 68, 173, 1);
  animation: pulse-purple 2s infinite;
}

@keyframes pulse-purple {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
  }
}
.leaflet-container .leaflet-control-attribution {
  display: none;
}
</style>